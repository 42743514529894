<template>
    <div class="navbar-container main-menu-content d-flex">
        <ul class="nav align-items-center mr-2">
            <b-link
                :class="['nav-link btn-toolbars-collapse text-white', { 'toolbars-shown': isToolbarsCollapsed }]"
                @click="updateToolbarsStatus"
            >
                <feather-icon size="20" icon="ArrowLeftIcon" />
            </b-link>
            <b-link class="nav-link text-white" to="/">
                <img class="psim-icon" :src="logoUrl" alt="SBO icon" />
                <span class="ml-1">SBO</span>
            </b-link>
        </ul>
        <horizontal-nav-menu-items :items="navMenuItems" />
        <ul class="nav align-items-center">
            <notification-dropdown class="mr-3" />
            <chat-dropdown class="mr-3" />
            <search-bar />
        </ul>
        <ul class="nav align-items-center ml-auto">
            <notification-dropdown />
            <locale />
            <user-dropdown />
        </ul>
    </div>
</template>

<script>
import store from '@/store';
import { BLink } from 'bootstrap-vue';
import navMenuItems from '@/navigation/horizontal';
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue';
import SearchBar from '@/@core/layouts/components/app-navbar/components/SearchBar.vue';
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue';
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue';
import ChatDropdown from '@/@core/layouts/components/app-navbar/components/ChatDropdown.vue';

export default {
    components: {
        BLink,
        HorizontalNavMenuItems,
        Locale,
        SearchBar,
        UserDropdown,
        NotificationDropdown,
        ChatDropdown,
    },
    data() {
        return {
            navMenuItems,
            logoUrl: require('@/assets/images/logo/icon-psim.png'),
        };
    },
    methods: {
        updateToolbarsStatus() {
            store.commit('psimConfig/UPDATE_TOOLBARS_COLLAPSED', !store.state.psimConfig.isToolbarsCollapsed);
        },
    },
    computed: {
        isToolbarsCollapsed() {
            return store.state.psimConfig.isToolbarsCollapsed;
        },
    },
};
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';

.psim-icon {
    height: 40px;
}
.btn-toolbars-collapse {
    transition: transform, 0.2s;
    &.toolbars-shown {
        transform: rotate(180deg);
    }
}
</style>
